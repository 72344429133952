import api from '@/plugins/axios.js'

export function saveSubscriptionPlan(subscriptionPlan) {
  return api.put('/subscription-plans/', subscriptionPlan)
}

export function findAllSubscriptionPlans() {
  return api.get('/subscription-plans/')
}

export function deleteSubscriptionPlan(id) {
  return api.delete(`/subscription-plans/${id}`)
}

export function loadSubscriptionPlanLinkedDevices(id) {
  return api.get(`/subscription-plans/${id}/devices`)
}

export function validateSubscriptionPlanName(id, name) {
  return api.get('/subscription-plans/validate/name', {
    params: {
      id: id,
      name: name
    }
  })
}
