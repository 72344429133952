import api from '@/plugins/axios.js'

export function findAllDealers() {
  return api.get('/dealers/get')
}

export function deleteDealer(id) {
  return api.delete('/dealers/delete', {
    params: {
      id: id
    }
  })
}

export function saveDealer(dealer) {
  return api.post('/dealers/save', dealer)
}

export function validateDealerName(id, name) {
  return api.get('/dealers/validate/name', {
    params: {
      id: id,
      name: name
    }
  })
}
