/* eslint-disable no-template-curly-in-string */
/* eslint-disable camelcase */
import { required, image, confirmed, length, email, min, max, min_value, max_value, alpha_dash, regex, size, numeric, double, excluded, required_if, is } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'
import { validateAccountUsername } from '@/api/accounts-api.js'
import { validateAccessGroupName } from '@/api/access-groups-api.js'
import { validateDomainName } from '@/api/domains-api.js'
import { validateStoryblokDomainName } from '@/api/storyblok-domains-api.js'
import { validateHexacode } from '@/api/devices-api.js'
import { validateMailUser } from '@/api/mail-users-api.js'
import { validateFtpAccount } from '@/api/ftp-accounts-api.js'
import { validateDealerName } from '@/api/dealers-api.js'
import { validateSubdealerName } from '@/api/subdealers-api.js'
import { validateSubscriptionPlanName } from '@/api/subscription-plans-api.js'
import { DateTime } from 'luxon'

// extend the vee-validate rules and provide a custom error message for each one
setInteractionMode('lazy')

const dateFormat = function(value, args) {
  return DateTime.fromFormat(value, args[0]).invalid === null
}
extend('is', is)
extend('dateFormat', {
  validate: dateFormat,
  message: (field, params, data) => {
    return `${field} is invalid, use ${params[0]} format`
  }
})
extend('after', {
  validate: (value, args) => {
    let target = args[0]
    if (typeof args[0] === 'string') {
      target = DateTime.fromISO(args[0]).toUTC()
    }
    const current = DateTime.fromFormat(value, 'dd-LL-yyyy HH:mm').setZone('utc', { keepLocalTime: true })
    return current >= target
  },
  message: (field, params, data) => {
    let target = params[0]
    if (typeof params[0] === 'string') {
      target = DateTime.fromISO(params[0]).toUTC()
    }
    return `${field} value must be after ${target.toFormat('dd-LL-yyyy HH:mm')}`
  }
})
extend('before', {
  validate: (value, args) => {
    let target = args[0]
    if (typeof args[0] === 'string') {
      target = DateTime.fromISO(args[0]).setZone('utc', { keepLocalTime: true })
    }
    const current = DateTime.fromFormat(value, 'dd-LL-yyyy HH:mm').setZone('utc', { keepLocalTime: true })
    return current <= target
  },
  message: (field, params, data) => {
    let target = params[0]
    if (typeof params[0] === 'string') {
      target = DateTime.fromISO(params[0]).setZone('utc', { keepLocalTime: true })
    }
    return `${field} value must be before ${target.toFormat('dd-LL-yyyy HH:mm')}`
  }
})

const uniqueAccountUsername = function(value, args) {
  return validateAccountUsername(args[0], value).then(r => {
    return {
      valid: r.data
    }
  })
}

const uniqueAccessGroupName = function(value, args) {
  return validateAccessGroupName(args[0], value).then(r => {
    return {
      valid: r.data
    }
  })
}
const uniqueDomainName = function(value, args) {
  return validateDomainName(args[0], value).then(r => {
    return {
      valid: r.data
    }
  })
}
const uniqueStoryblokDomainName = function(value, args) {
  return validateStoryblokDomainName(args[0], value).then(r => {
    return {
      valid: r.data
    }
  })
}
const uniqueSubscriptionPlanName = function(value, args) {
  return validateSubscriptionPlanName(args[0], value).then(r => {
    return {
      valid: r.data
    }
  })
}

const uniqueDealerName = function(value, args) {
  return validateDealerName(args[0], value).then(r => {
    return {
      valid: r.data
    }
  })
}

const uniqueSubdealerName = function(value, args) {
  return validateSubdealerName(args[0], value).then(r => {
    return {
      valid: r.data
    }
  })
}

const uniqueHexacode = function(value, args) {
  return validateHexacode(args[0], value).then(r => {
    return {
      valid: r.data
    }
  })
}
const uniqueMailUser = function(value, args) {
  return validateMailUser(args[0], value).then(r => {
    return {
      valid: r.data
    }
  })
}
const uniqueFtpAccount = function(value, args) {
  return validateFtpAccount(args[0], value).then(r => {
    return {
      valid: r.data
    }
  })
}
extend('regex', regex)

const deviceTemplate = function(value, args) {
  if (args[0] === 'BUFFER_EXPORT_TEMPLATE') {
    var regex = /\$\{(N:(\d{1,2})),(D:(-?\d{1,2})),(P:(\d{1,2})),(L:(\d{1,2})),(PC:(\d{1,2})),?(O:(CREATE-\d{1,2}:\d{2}-\d+[S]|DELETE))?,?(\DF:'(.*)')?,?(VF:(%(\d+)?(\.\d+)?([a-z]{1})?))?\}/g
    var found = value.match(regex)
    if (found == null) {
      return false
    }
  } else if (args[0] === 'ALARM_EXPORT_TEMPLATE') {
    if (value.indexOf('${content}') === -1) {
      return false
    }
  } else if (args[0] === 'OPTIMABOX_EXPORT_TEMPLATE') {
    if (value.indexOf('${dataBlocks}') === -1) {
      return false
    }
  }
  return true
}

const domainPattern = function(value, args) {
  var regex = /^[a-zA-Z0-9.-]+$/
  var found = value.match(regex)
  if (found == null) {
    return false
  }
  return true
}
const dataTemplate = function(value) {
  if (value.indexOf('${V}') === -1) {
    return false
  }
  if (value.indexOf('${D}') === -1) {
    return false
  }
  return true
}

const alarmDeviceTemplate = function(value) {
  if (value.indexOf('${content}') === -1) {
    return false
  }
  return true
}

const departmentTemplate = function(value) {
  if (value.indexOf('${alarmElements}') === -1) {
    return false
  }
  return true
}

const dataBlockTemplate = function(value) {
  if (value.indexOf('${tagId}') === -1) {
    return false
  }
  if (value.indexOf('${visits}') === -1) {
    return false
  }
  return true
}
const elementTemplate = function(value) {
  if (value.indexOf('${date}') === -1) {
    return false
  }
  if (value.indexOf('${code}') === -1) {
    return false
  }
  return true
}
const visitTemplate = function(value) {
  if (value.indexOf('${date}') === -1) {
    return false
  }
  return true
}
// const isImage = function(value) {
//   return false
// }
extend('image', image)
extend('required', required)
extend('excluded', excluded)
extend('numeric', numeric)
extend('email', email)
extend('confirmed', confirmed)
extend('length', length)
extend('min_value', min_value)
extend('max', max)
extend('max_value', max_value)
extend('alpha_dash', alpha_dash)
extend('double', double)
extend('required_if', required_if)
extend('size', {
  ...size,
  message: (field, params, data) => {
    return `File size must be max: ${params.size}Kb`
  }
})
extend('unique_hexacode', {
  validate: uniqueHexacode,
  message: (field, params, data) => {
    return `${field} already exists`
  }
})
extend('min', {
  ...min,
  message: (field, params, data) => {
    return `Please enter at least ${params.length} characters`
  }
})
extend('password', {
  ...regex,
  message: (field) => {
    return `${field} must contain at least an uppercase character, an lowercase character and a digit.`
  }
})
extend('required', {
  ...required,
  message: (field, params, data) => {
    return `${field} is required`
  }
})

extend('unique_mail_user', {
  validate: uniqueMailUser,
  message: (field, params, data) => {
    return `${field} already exists`
  }
})
extend('unique_ftp_user', {
  validate: uniqueFtpAccount,
  message: (field, params, data) => {
    return `${field} already exists`
  }
})
extend('unique_access_group_name', {
  validate: uniqueAccessGroupName,
  message: (field, params, data) => {
    return `${field} already exists`
  }
})
extend('unique_domain_name', {
  validate: uniqueDomainName,
  message: (field, params, data) => {
    return `${field} already exists`
  }
})
extend('unique_storyblok_domain_name', {
  validate: uniqueStoryblokDomainName,
  message: (field, params, data) => {
    return `${field} already exists`
  }
})
extend('domain_pattern', {
  validate: domainPattern,
  message: (field, params, data) => {
    return `${field} is incorrect`
  }
})
extend('unique_subscription_plan_name', {
  validate: uniqueSubscriptionPlanName,
  message: (field, params, data) => {
    return `${field} already exists`
  }
})
extend('unique_dealer_name', {
  validate: uniqueDealerName,
  message: (field, params, data) => {
    return `${field} already exists`
  }
})
extend('unique_subdealer_name', {
  validate: uniqueSubdealerName,
  message: (field, params, data) => {
    return `${field} already exists`
  }
})
extend('unique_username', {
  validate: uniqueAccountUsername,
  message: (field, params, data) => {
    return `${field} already exists`
  }
})
extend('uppercase_hexacode', {
  validate: function(value, args) {
    return /([A-F0-9]{2}.?){6}/.test(value)
  },
  message: (field, params, data) => {
    return 'Characters not in range A-F and 0-9'
  }
})
extend('ip', {
  validate: function(value, args) {
    return /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/.test(value)
  },
  message: (field, params, data) => {
    return 'Ip address is not valid'
  }
})

extend('device_template', {
  validate: deviceTemplate,
  message: (field, params, data) => {
    if (params[0] === 'ALARM_EXPORT_TEMPLATE') {
      return 'Please add value tag ${content}'
    } else if (params[0] === 'OPTIMABOX_EXPORT_TEMPLATE') {
      return 'Please add value tag ${dataBlocks}'
    }
    return 'Please add at least one buffer template ${N:x,D:x,P:x,L:x,PC:x}'
  }
})
extend('data_template', {
  validate: dataTemplate,
  message: (field, params, data) => {
    var tag = ''
    if (params._value_.indexOf('${D}') === -1) {
      tag = '${D}'
    } else if (params._value_.indexOf('${V}') === -1) {
      tag = '${V}'
    }
    return 'Please add value tag ' + tag
  }
})

extend('alarm_device_template', {
  validate: alarmDeviceTemplate,
  message: (field, params, data) => {
    return 'Please add value tag ${content}'
  }
})

extend('department_template', {
  validate: departmentTemplate,
  message: (field, params, data) => {
    return 'Please add data tag ${alarmElements}'
  }
})

extend('element_template', {
  validate: elementTemplate,
  message: (field, params, data) => {
    var tag = ''
    if (params._value_.indexOf('${date}') === -1) {
      tag = '${date}'
    } else if (params._value_.indexOf('${code}') === -1) {
      tag = '${code}'
    }
    return 'Please add data tag ' + tag
  }
})
extend('data_block_template', {
  validate: dataBlockTemplate,
  message: (field, params, data) => {
    var tag = ''
    if (params._value_.indexOf('${tagId}') === -1) {
      tag = '${tagId}'
    } else if (params._value_.indexOf('v{visits}') === -1) {
      tag = '${visits}'
    }
    return 'Please add data tag ' + tag
  }
})
extend('visit_template', {
  validate: visitTemplate,
  message: (field, params, data) => {
    return 'Please add data tag ${date}'
  }
})

extend('alarm_export_content_wrapper_template', {
  validate: (value, args) => {
    return /.*\$\{content\}.*/.test(value)
  },
  message: (field, params, data) => {
    return 'Please add the ${content} tag'
  }
})

extend('buffer_export_content_wrapper_template', {
  validate: (value, args) => {
    return /.*\$\{content\}.*/.test(value)
  },
  message: (field, params, data) => {
    return 'Please add the ${content} tag'
  }
})

extend('optimabox_export_content_wrapper_template', {
  validate: (value, args) => {
    return /.*\$\{content\}.*/.test(value)
  },
  message: (field, params, data) => {
    return 'Please add the ${content} tag'
  }
})

// extend('image', {
//   validate: isImage,
//   message: (field, params, data) => {
//     return 'Please select a valid image file'
//   }
// })
