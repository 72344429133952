import api from '@/plugins/axios.js'

export function paginate(request) {
  return api.post('/ftp-accounts/paginate', request)
}

export function saveFtpAccount(account) {
  return api.post('/ftp-accounts/', account)
}

export function deleteFtpAccount(id) {
  return api.delete('/ftp-accounts/' + id)
}

export function validateFtpAccount(id, username) {
  const params = {
    username: username
  }
  if (id !== null && id !== 'null') {
    params.id = id
  }
  return api.get('/ftp-accounts/username', { params })
}
