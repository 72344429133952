import api from '@/plugins/axios.js'

export function paginate(request) {
  return api.post('/mail-users/paginate', request)
}

export function saveMailUser(account) {
  return api.post('/mail-users/', account)
}

export function deleteMailUser(id) {
  return api.delete('/mail-users/' + id)
}

export function validateMailUser(id, email) {
  const params = {
    email: email
  }
  if (id !== 'null' && id !== null) {
    params.id = id
  }
  return api.get('/mail-users/validate/email', { params })
}
