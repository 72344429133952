import Vue from 'vue'
import { GRANTS_MIXIN } from '@/store/security/grants.js'
import clonedeep from 'lodash.clonedeep'
import { v4 as randomUUID } from 'uuid'
import GenericPrompt from '@/components/GenericPrompt.vue'
import GenericNotification from '@/components/GenericNotification.vue'
import vuetify from './plugins/vuetify'

Vue.mixin({
  methods: {
    ...GRANTS_MIXIN,
    randomUUID,
    showErrorNotification(message) {
      const config = {
        title: message,
        type: 'error'
      }
      return this.showNotification(config)
    },
    showSuccessNotification(message) {
      const config = {
        title: message,
        type: 'success'
      }
      return this.showNotification(config)
    },
    showSuccessDialog(text) {
      const config = {
        text: text,
        success: true
      }
      return this.showDialog(config)
    },
    showErrorDialog(text) {
      const config = {
        text: text,
        error: true
      }
      return this.showDialog(config)
    },
    showTextPromptDialog(title, label, rules, value = '') {
      const config = {
        type: 'prompt',
        title: title,
        inputValue: value,
        inputLabel: label,
        validationRules: rules
      }
      return this.showDialog(config)
    },
    showConfirmationDialog(message) {
      const config = {
        type: 'confirmation',
        text: message
      }
      return this.showDialog(config)
    },
    showProgressDialog(message) {
      const config = {
        type: 'progress',
        text: message
      }
      const Component = Vue.extend(GenericPrompt)
      const instance = new Component({
        vuetify,
        propsData: {
          ...config
        }
      })
      instance.$mount()
      this.$el.appendChild(instance.$el)
      const controller = {
        resolve: null,
        reject: null,
        promise: null
      }
      const promise = new Promise((resolve, reject) => {
        controller.resolve = function() {
          resolve(null)
          instance.$destroy()
        }
        controller.reject = function(error) {
          reject(error)
          instance.$destroy()
        }
        instance.$on('confirmed', r => {
          resolve(r)
          instance.$destroy()
        })
        instance.$on('cancelled', r => {
          reject(new Error('cancelled', r))
          instance.$destroy()
        })
      })
      controller.promise = promise
      return controller
    },
    showDialog(config) {
      const Component = Vue.extend(GenericPrompt)
      const instance = new Component({
        vuetify,
        propsData: {
          ...config
        }
      })
      instance.$mount()
      this.$el.appendChild(instance.$el)
      return new Promise((resolve, reject) => {
        instance.$on('confirmed', r => {
          resolve(r)
          instance.$destroy()
        })
        instance.$on('cancelled', r => {
          reject(new Error('cancelled', r))
          instance.$destroy()
        })
      })
    },
    showNotification(config) {
      const Component = Vue.extend(GenericNotification)
      const instance = new Component({
        vuetify,
        propsData: {
          ...config
        }
      })
      instance.$mount()
      this.$root.$el.appendChild(instance.$el)
      return new Promise((resolve, reject) => {
        instance.$on('hidden', r => {
          resolve()
          instance.$destroy()
        })
      })
    },
    getHexacode(rawHexacode) {
      let hexacode = (rawHexacode).toString(16)
      for (let i = 0; i < 12 - hexacode.length; i++) {
        hexacode += '0'
      }
      for (let i = 2; i < 17; i += 3) {
        hexacode = hexacode.slice(0, i) + '.' + hexacode.slice(i)
      }
      return hexacode.toUpperCase()
    },
    getRawHexacode(hexacode) {
      if (hexacode !== null && hexacode !== undefined) {
        return parseInt(hexacode.replaceAll('.', ''), 16)
      }
      console.warn('Passed hexcode is empty')
      return null
    },
    clone(target) {
      return clonedeep(target)
    },
    computeStatusColor(status) {
      if (status === 'ON') {
        return 'green'
      } else if (status === 'ON_ALARM') {
        return 'red'
      }
      return 'grey'
    },
    computeStatusIcon(status) {
      if (status === 'ON') {
        return 'mdi-wifi-check'
      } else if (status === 'OFF') {
        return 'mdi-wifi-remove'
      } else if (status === 'ON_ALARM') {
        return 'mdi-bell-ring-outline'
      }
      return 'mdi-bell-ring'
    },
    computeHostTypeColor(host) {
      if (host === 'VS100_01-01.00') {
        return 'teal lighten-1'
      } else if (host === 'BG101_01-01.00') {
        return 'blue lighten-1'
      }
      return 'deep-purple lighten-1'
    },
    computeExportType(type) {
      let text = ''
      switch (type) {
        case 'FTP_ALARM':
          text = 'FTP alarms export'
          break
        case 'FTP_BUFFERS':
          text = 'FTP buffers export'
          break
        case 'FTP_OPTIMA_BOX':
          text = 'FTP optimabox export'
          break
        case 'MAIL_ALARM':
          text = 'Mail alarms export'
          break
        case 'MAIL_BUFFERS':
          text = 'Mail buffers export'
          break
        default: {
          break
        }
      }
      return text
    },
    computeExportStatus(status) {
      let text = ''
      switch (status) {
        case 'SUCCESS':
          text = 'Successfull'
          break
        case 'PARTIALLY_SUCCESFULL':
          text = 'Partially succesfull'
          break
        case 'FAILED':
          text = 'Failed'
          break
        default:
          break
      }
      return text
    },
    downloadFile(fileName, dataBlob) {
      var fileURL = window.URL.createObjectURL(dataBlob)
      var fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)

      fileLink.click()
      fileLink.remove()
      window.URL.revokeObjectURL(fileURL)
    },
    addRequestParams(config) {
      var params = '?'
      var keys = Object.keys(config)
      var i = 0
      var length = keys.length
      for (i; i < length; i++) {
        var name = keys[i]
        if (config[name] != null) {
          params += name + '=' + encodeURIComponent(config[name])
        }
        if (i + 1 < length) {
          params += '&'
        }
      }
      return params
    }
  }
})
