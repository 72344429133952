/* eslint-disable no-template-curly-in-string */
/* eslint-disable camelcase */
import { extend } from 'vee-validate'

extend('alarmExportDeviceTemplate', {
  validate: function(value, args) {
    if (value.indexOf('${content}') === -1) {
      return false
    }
    return true
  },
  message: (field, params, data) => {
    return 'Please add value tag ${content}'
  }
})

extend('alarmExportDepartmentTemplate', {
  validate: function(value, args) {
    if (value.indexOf('${alarmElements}') === -1) {
      return false
    }
    return true
  },
  message: (field, params, data) => {
    return 'Please add data tag ${alarmElements}'
  }
})
extend('alarmExportElementTemplate', {
  validate: function(value, args) {
    if (value.indexOf('${date}') === -1) {
      return false
    }
    if (value.indexOf('${code}') === -1) {
      return false
    }
    return true
  },
  message: (field, params, data) => {
    var tag = ''
    if (params._value_.indexOf('${date}') === -1) {
      tag = '${date}'
    } else if (params._value_.indexOf('${code}') === -1) {
      tag = '${code}'
    }
    return 'Please add data tag ' + tag
  }
})
