import api from '@/plugins/axios.js'

export function findAccessGroups() {
  return api.get('/access-groups/get')
}

export function saveAccessGroup(saveRequest) {
  return api.post('/access-groups/save', saveRequest)
}

export function deleteAccessGroup(id) {
  return api.delete(`/access-groups/delete/${id}`)
}

export function findAccessGroupsByHexacode(hexacode) {
  return api.get('/access-groups/by-hexacode', {
    params: {
      hexacode: hexacode
    }
  })
}

export function findAccessGroupsByAccount(id) {
  return api.get('/access-groups/by-client-id', {
    params: {
      id: id
    }
  })
}

export function findAccessGroupDetails(id) {
  return api.get('/access-groups/permissions', { params: { id } })
}

export function validateAccessGroupName(id, name) {
  return api.get('/access-groups/validate/name', {
    params: {
      id: id,
      name: name
    }
  })
}
