import LocalStorage from '@/plugins/local-storage.js'
import clonedeep from 'lodash.clonedeep'

export default {
  namespaced: true,
  state: {
    devices: {
      headers: [],
      filter: {
        demo: null,
        tags: [],
        dealer: null,
        subdealer: null,
        accountId: null,
        statuses: [],
        searchHint: '',
        type: null,
        addressingType: null
      },
      sortField: 'hexacode',
      sortDirection: 'DESC',
      pageLength: 10
    },
    accounts: {
      headers: [],
      filter: {
        demo: null,
        roles: [],
        use2FA: null,
        locked: null,
        domains: [],
        linkedRoles: [],
        exportClient: null,
        searchHint: ''
      },
      sortField: 'username',
      sortDirection: 'DESC',
      pageLength: 10
    }
  },
  getters: {
    devicesFilter(state) {
      return state.devices.filter
    },
    devicesHeaders(state) {
      return state.devices.headers
    }
  },
  mutations: {
    loadLocalStorage(state) {
      const tables = LocalStorage.get('tables')
      if (tables !== null) {
        const devicesFilter = { ...state.devices.filter, ...tables.devices.filter }
        tables.devices.filter = devicesFilter
        state.devices = { ...state.devices, ...tables.devices }
        const accountsFilter = { ...state.accounts.filter, ...tables.accounts.filter }
        tables.accounts.filter = accountsFilter
        state.accounts = { ...state.accounts, ...tables.accounts }
      }
    },
    updateFilter(state, data) {
      state[data.name].filter = data.filter
    },
    updateHeaders(state, data) {
      state[data.name].headers = data.headers
    },
    updateProperty(state, data) {
      state[data.name][data.property] = data.value
    },
    logout(state) {
      state.devices = {
        headers: [],
        filter: {
          demo: null,
          tags: [],
          dealer: null,
          subdealer: null,
          accountId: null,
          statuses: [],
          searchHint: '',
          type: null,
          addressingType: null
        },
        sortField: 'hexacode',
        sortDirection: 'DESC',
        pageLength: 10
      }
      state.accounts = {
        headers: [],
        filter: {
          demo: null,
          roles: [],
          use2FA: null,
          locked: null,
          domains: [],
          linkedRoles: [],
          exportClient: null,
          searchHint: ''
        },
        sortField: 'username',
        sortDirection: 'DESC',
        pageLength: 10
      }
      LocalStorage.remove('tables')
    }
  },
  actions: {
    updateAccountsTable(context, data) {
      context.commit('updateFilter', {
        name: 'accounts',
        filter: {
          demo: data.paginationRequest.demo,
          roles: [...data.paginationRequest.roles],
          use2FA: data.paginationRequest.use2FA,
          locked: data.paginationRequest.locked,
          domains: [...data.paginationRequest.domains],
          linkedRoles: [...data.paginationRequest.linkedRoles],
          searchHint: data.paginationRequest.searchHint,
          exportClient: data.paginationRequest.exportClient
        }
      })
      context.commit('updateHeaders', { name: 'accounts', headers: clonedeep(data.headers) })
      context.commit('updateProperty', { name: 'accounts', property: 'pageLength', value: data.paginationRequest.pageLength })
      context.commit('updateProperty', { name: 'accounts', property: 'sortDirection', value: data.paginationRequest.sortDirection })
      context.commit('updateProperty', { name: 'accounts', property: 'sortField', value: data.paginationRequest.sortField })
      LocalStorage.put('tables', this.state.tables)
    },
    updateDevicesTable(context, data) {
      context.commit('updateFilter', {
        name: 'devices',
        filter: {
          demo: data.paginationRequest.demo,
          tags: [...data.paginationRequest.tags],
          dealer: data.paginationRequest.dealer,
          subdealer: data.paginationRequest.subDealer,
          accountId: data.paginationRequest.accountId,
          statuses: [...data.paginationRequest.statuses],
          searchHint: data.paginationRequest.searchHint,
          type: data.paginationRequest.type,
          addressingType: data.paginationRequest.addressingType
        }
      })
      context.commit('updateHeaders', { name: 'devices', headers: clonedeep(data.headers) })
      context.commit('updateProperty', { name: 'devices', property: 'pageLength', value: data.paginationRequest.pageLength })
      context.commit('updateProperty', { name: 'devices', property: 'sortDirection', value: data.paginationRequest.sortDirection })
      context.commit('updateProperty', { name: 'devices', property: 'sortField', value: data.paginationRequest.sortField })
      LocalStorage.put('tables', this.state.tables)
    }
  }
}
