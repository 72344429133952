import LocalStorage from '@/plugins/local-storage.js'
import { logout } from '@/api/accounts-api.js'
import router from '@/router'
import { GRANTS } from '@/store/security/grants.js'

export default {
  namespaced: true,
  state: {
    currentUser: null,
    roles: [],
    grants: null
  },
  getters: {
    apiToken(state) {
      if (state.currentUser != null) {
        return state.currentUser.token.id
      }
      return null
    },
    username(state) {
      if (state.currentUser != null) {
        return state.currentUser.metadata.username
      }
      return null
    },
    id(state) {
      if (state.currentUser != null) {
        return state.currentUser.token.user
      }
      return null
    },
    roles(state) {
      return state.roles
    },
    grants(state) {
      return state.grants
    },
    availablePcNumbers(state) {
      let pcNumbers = state.currentUser.metadata.pcNumbers
      if (GRANTS.APPLY_ACL.WRITE()) {
        pcNumbers = Array.from({ length: 99 }, (value, index) => index + 1)
      }
      return pcNumbers
    }
  },
  mutations: {
    logout(state) {
      state.currentUser = null
      state.roles = []
      state.grants = null
      LocalStorage.remove('current-user')
      this._vm.$disconnect()
    },
    login(state, currentUser) {
      state.currentUser = currentUser
      const grants = {}
      state.currentUser.token.authorities.forEach((a) => {
        grants[a.authority] = a.type
      })
      state.grants = grants
      state.currentUser.token.authorities.forEach((a) =>
        state.roles.push(a.authority)
      )
      this._vm.$connect(state.currentUser.token.id)
      LocalStorage.put('current-user', currentUser)
    },
    loadLocalStorage(state) {
      const currentUser = LocalStorage.get('current-user')
      if (currentUser !== null && currentUser.token) {
        state.currentUser = currentUser
        state.currentUser.token.authorities.forEach((a) =>
          state.roles.push(a.authority)
        )
        const grants = {}
        state.currentUser.token.authorities.forEach((a) => {
          grants[a.authority] = a.type
        })
        state.grants = grants
        this._vm.$connect(state.currentUser.token.id)
      }
    },
    updateVapidSubscription(state, vapidSubscription) {
      state.currentUser.metadata.vapidSubscription = vapidSubscription
      LocalStorage.put('current-user', state.currentUser)
    }
  },
  actions: {
    async logout(context) {
      return logout()
        .then(() => context.commit('logout'))
        .then(() => router.push({ name: 'Login' }))
        .catch(() => {})
    },
    login(context, currentUser) {
      context.commit('login', currentUser)
    },
    updateVapidSubscription(context, vapidSubscription) {
      context.commit('updateVapidSubscription', vapidSubscription)
    }
  }
}
