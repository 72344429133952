import api from '@/plugins/axios.js'

export function logout() {
  return api.post('/logout')
}
export function login(username, password, rememberMe, mfaCode) {
  if (mfaCode !== null) {
    return api.post('/login', {
      username: username,
      password: password,
      rememberMe: rememberMe,
      mfaCode: mfaCode,
      type: 'BASIC_MFA'
    })
  } else {
    return api.post('/login', {
      username: username,
      password: password,
      rememberMe: rememberMe,
      type: 'BASIC'
    })
  }
}
export function findAvailableAccessType() {
  return api.get('/accounts/access-type')
}
// to be removed in the future, replace with paginate/exclude
export function getAccountsSelection() {
  return api.get('/accounts/accounts-selection')
}
export function paginateAccounts(paginationRequest) {
  return api.post('/accounts/paginate', paginationRequest)
}
export function paginateWithExclusion(paginationRequest) {
  return api.post('/accounts/paginate/exclude', paginationRequest)
}
export function saveAccount(accountData) {
  return api.post('/accounts/save', accountData)
}
export function deleteAccount(id) {
  return api.delete(`/accounts/${id}/delete`)
}
export function lockAccount(id, message) {
  return api.put(`/accounts/${id}/lock`, message)
}
export function unlockAccount(id) {
  return api.put(`/accounts/${id}/unlock`)
}
export function findAccountById(id) {
  return api.get(`/accounts/${id}/get`)
}
export function validateAccountUsername(id, username) {
  return api.get('/accounts/validate/username', {
    params: {
      id: id,
      name: username
    }
  })
}
export function findDirectLinkedDevicesForAccount(id) {
  return api.get('/accounts/direct-permissions', {
    params: {
      id: id
    }
  })
}
export function saveAccountPermissions(id, permissions) {
  return api.put(`/accounts/${id}/permissions`, permissions)
}

export function getAccountSessions(id) {
  return api.get(`/accounts/${id}/sessions`)
}
export function deleteAccountSession(session) {
  return api.delete(`/accounts/sessions/${session}`)
}

export function findExportSettings(id) {
  return api.get(`/accounts/${id}/export/settings`)
}
export function saveExportSettings(id, exportSettings) {
  return api.post(`/accounts/${id}/export/settings`, exportSettings)
}
export function getCronNextExecutionDates(count, expression) {
  return api.get('/accounts/export/cron-execution-dates', {
    params: {
      count: count,
      expression: expression
    }
  })
}
export function startExport(id, type) {
  return api.post(`/accounts/${id}/export/start`, null, {
    params: {
      type: type
    }
  })
}
export function getExportClients() {
  return api.get(`${process.env.VUE_APP_API_DATA_EXPORT_URL}/get`)
}

export function getExportClientSettings(exportClientId) {
  return api.get(`${process.env.VUE_APP_API_DATA_EXPORT_URL}/${exportClientId}/settings`)
}

export function getPersonalSettings() {
  return api.get('/accounts/settings/get')
}

export function savePersonalSettings(personalSettings) {
  return api.post('/accounts/settings/save', personalSettings)
}

export function getOptilinkApiTokens(accountId) {
  return api.get('/accounts/optilink-api-tokens', { params: { accountId: accountId } })
}

export function saveOptilinkApiToken(token) {
  return api.put('/accounts/optilink-api-tokens', token)
}

export function deleteOptilinkApiToken(tokenId) {
  return api.delete('/accounts/optilink-api-tokens', {
    params: {
      id: tokenId
    }
  })
}
export function recoverPassword(email) {
  return api.put('/accounts/recover-password', null, {
    params: {
      email: email
    }
  })
}
export function validateRecoverPasswordToken(token) {
  return api.get('/accounts/recover-password', {
    params: {
      token: token
    }
  })
}
export function finishRecoverPassword(token, newPassword) {
  return api.post('/accounts/recover-password', newPassword, {
    params: {
      token: token
    }
  })
}
export function setup2Fa() {
  return api.get('/accounts/settings/2fa')
}
export function verify2Fa(secret, code) {
  return api.post('/accounts/verify-2fa', secret, {
    params: {
      code: code
    }
  })
}
