import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import { grantsCheck } from '@/store/security/grants.js'
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.grants || record.meta.authenticationRequired)) {
    if (store.getters['security/apiToken'] === null) {
      return next({ name: 'Login' })
    } else if (to.meta.grants !== undefined && !grantsCheck(to.meta.grants)) {
      return next({ name: 'Unauthorized' })
    }
  }
  return next()
})

export default router
