/* eslint-disable no-template-curly-in-string */
/* eslint-disable camelcase */
import { extend } from 'vee-validate'

extend('optimaBoxV1ExportDeviceTemplate', {
  validate: function(value, args) {
    if (value.indexOf('${dataBlocks}') === -1) {
      return false
    }
    return true
  },
  message: (field, params, data) => {
    return 'Please add value tag ${dataBlocks}'
  }
})

extend('optimaBoxV1ExportDataBlockTemplate', {
  validate: function(value, args) {
    if (value.indexOf('${tagId}') === -1) {
      return false
    }
    if (value.indexOf('${visits}') === -1) {
      return false
    }
    return true
  },
  message: (field, params, data) => {
    var tag = ''
    if (params._value_.indexOf('${tagId}') === -1) {
      tag = '${tagId}'
    } else if (params._value_.indexOf('v{visits}') === -1) {
      tag = '${visits}'
    }
    return 'Please add data tag ' + tag
  }
})

extend('optimaBoxV1ExportVisitTemplate', {
  validate: function(value, args) {
    if (value.indexOf('${date}') === -1) {
      return false
    }
    return true
  },
  message: (field, params, data) => {
    return 'Please add data tag ${date}'
  }
})
