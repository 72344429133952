import axios from 'axios'
import store from '../store'
const API_URL = window.origin + '/api'

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(function(config) {
  config.headers.Authorization = 'Bearer ' + store.getters['security/apiToken']
  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (// error.response.status === 403
      (error.response && error.response.status === 401) &&
      store.getters['security/apiToken'] != null
    ) {
      store.commit('logout')
    }
    return Promise.reject(error)
  }
)
export default api
