import api from '@/plugins/axios.js'

export function findAllSubdealers() {
  return api.get('/sub-dealers/get')
}

export function deleteSubdealer(id) {
  return api.delete('/sub-dealers/delete', {
    params: {
      id: id
    }
  })
}

export function saveSubdealer(subdealer) {
  return api.post('/sub-dealers/save', subdealer)
}

export function validateSubdealerName(id, name) {
  return api.get('/sub-dealers/validate/name', {
    params: {
      id: id,
      name: name
    }
  })
}
