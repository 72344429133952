import Vue from 'vue'

const removeChildIfMatched = function(el, binding) {
  let access = false
  if (binding.value === true || binding.value === false) {
    access = binding.value
  } else {
    access = binding.value()
  }
  if (!access && el.parentNode !== null) {
    el.parentNode.removeChild(el)
  }
}

export const hasAccess = Vue.directive('hasAccess', {
  bind(el, binding, vnode, prevVnode) {
  },
  inserted(el, binding, vnode, prevVnode) {
    removeChildIfMatched(el, binding)
  },
  update(el, binding, vnode, prevVnode) {
    removeChildIfMatched(el, binding)
  },
  componentUpdated(el, binding, vnode, prevVnode) {
    removeChildIfMatched(el, binding)
  },
  unbind(el, binding, vnode, prevVnode) {
  }
})
