import Vue from 'vue'
import Vuex from 'vuex'
import security from './security'
import tables from './tables'
import clusterState from './cluster-state'
import LocalStorage from '@/plugins/local-storage.js'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {},
  mutations: {
    loadLocalStorage(state) {
      this.commit('security/loadLocalStorage')
      this.commit('tables/loadLocalStorage')
    },
    logout(state) {
      this.dispatch('security/logout')
      this.commit('tables/logout')
    }
  },
  actions: {
    'loadTheme'(context, $vuetify) {
      const theme = LocalStorage.get('theme')
      if (theme !== null) {
        $vuetify.framework.theme.dark = (theme === 'dark')
      }
    }
  },
  modules: { security, tables, clusterState }
})
