import api from '@/plugins/axios.js'

export function paginate(paginationRequest) {
  return api.post('/devices/paginate-new', paginationRequest)
}
export function paginateStatusOverview(paginationRequest) {
  return api.post('/devices/paginate/status-overview', paginationRequest)
}
export function findAvailableTags() {
  return api.get('/devices/get/tags')
}
export function findAlarmHistory(hexacode) {
  return api.get('/devices/alarm-history', {
    params: {
      hexacode: hexacode
    }
  })
}
export function clearAlarmHistory(hexacode) {
  return api.delete('/devices/clear-alarm-history', {
    params: {
      hexacode: hexacode
    }
  })
}
export function findDeviceByHexacode(hexacode) {
  return api.get(`/devices/get/${hexacode}`)
}
export function deleteMapping(mapping) {
  return api.post('/devices/delete-mapping', mapping)
}
export function deleteDevice(hexacode) {
  return api.delete('/devices/delete', {
    params: {
      hexacode: hexacode
    }
  })
}
export function getDeviceTimeline(hexacode, startDate, endDate) {
  return api.get(`/devices/${hexacode}/connection-graph`, {
    params: {
      startDate: startDate,
      endDate: endDate
    }
  })
}
export function getDefaultCacheSettings() {
  return api.get('/devices/cache/get')
}
export function findDeviceSelectionByType(types) {
  return api.post('/devices/get/device-selection-by-type', types)
}
export function findRealDeviceDepartments(hexacode) {
  return api.get('/departments/real/' + hexacode)
}
export function validateHexacode(id, hexacode) {
  return api.get('/devices/validate/hexacode', {
    params: {
      id: id,
      hexacode: hexacode
    }
  })
}
export function saveDevice(device) {
  return api.post('/devices/save', device)
}
export function updateDevice(device) {
  return api.put('/devices/update', device)
}
export function sendCommand(command) {
  return api.post('/devices/sendCommand', command)
}

export function paginateWithExclusion(paginationRequest) {
  return api.post('/devices/paginate/exclude', paginationRequest)
}

export function paginateOptimaBoxWithExclusion(paginationRequest) {
  return api.post('/devices/optimabox/paginate/exclude', paginationRequest)
}

export function getDeviceSelection(paginationRequest) {
  return api.post('/devices/paginate/device-selection', paginationRequest)
}
export function findWebClientsByHexacode(hexacode) {
  return api.get(`/devices/${hexacode}/web-clients`)
}
export function findLinkedClientsByHexacode(hexacode) {
  return api.get(`/devices/${hexacode}/linked-clients`)
}
export function findLinkedAccessGroupsByHexacode(hexacode) {
  return api.get(`/devices/${hexacode}/access-groups`)
}
export function saveDevicePermissions(hexacode, permissions) {
  return api.post(`/devices/${hexacode}/permissions/save`, permissions)
}

export function findDeviceResponseChartData(hexacode) {
  return api.get(`/devices/metrics/${hexacode}`)
}
export function resetFirstConnectionDate(hexacode) {
  return api.put(`/devices/${hexacode}/reset-first-connection`)
}

export function findBlacklistedDevices() {
  return api.get('/devices/blacklist')
}
export function addDeviceToBlacklist(hexacode) {
  return api.put(`/devices/blacklist/${hexacode}`)
}
export function removeDeviceFromBlacklist(hexacode) {
  return api.delete(`/devices/blacklist/${hexacode}`)
}
export function disconnectDevice(hexacode) {
  return api.get(`/devices/${hexacode}/disconnect`)
}
export function downloadFile(exportFilters) {
  return api.get('/devices/report', { params: exportFilters, responseType: 'blob' })
}
export function updateSimNumber(hexacode, simNumber) {
  return api.post(`/devices/${hexacode}/simNumber/update`, simNumber, null)
}
export function getDevicesLocation(paginationRequest) {
  return api.post('/devices/devices-location', paginationRequest)
}
