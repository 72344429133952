/* eslint-disable no-template-curly-in-string */
/* eslint-disable camelcase */
import { extend } from 'vee-validate'

extend('bufferExportDeviceTemplate', {
  validate: function(value, args) {
    var regex = /\$\{(N:(-?\d{1,2})),(D:(-?\d{1,2})),(P:(\d{1,2})),(L:(\d{1,2})),(PC:(\d{1,2})),?(O:(CREATE-\d{1,2}:\d{2}-\d+[S]|DELETE))?,?(\DF:'(.*)')?,?(VF:(%(\d+)?(\.\d+)?([a-z]{1})?))?\}/g
    var found = value.match(regex)
    if (found == null) {
      return false
    }
    return true
  },
  message: (field, params, data) => {
    return 'Please add at least one buffer template token ${N:x,D:x,P:x,L:x,PC:x}'
  }
})

extend('bufferExportDataTemplate', {
  validate: function(value, args) {
    if (value.indexOf('${V}') === -1) {
      return false
    }
    if (value.indexOf('${D}') === -1) {
      return false
    }
    return true
  },
  message: (field, params, data) => {
    var tag = ''
    if (params._value_.indexOf('${D}') === -1) {
      tag = '${D}'
    } else if (params._value_.indexOf('${V}') === -1) {
      tag = '${V}'
    }
    return 'Please add value tag ' + tag
  }
})
