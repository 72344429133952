export default {
  namespaced: true,
  state: {
    nodes: [],
    counters: {
      registered: {
        name: 'Registered',
        realDevices: 0,
        virtualDevices: 0,
        bussinessGroups: 0,
        clients: 0,
        webClients: 0
      },
      connected: {
        name: 'Connected',
        realDevices: 0,
        virtualDevices: 0,
        bussinessGroups: 0,
        clients: 0,
        webClients: 0
      }
    }
  },
  mutations: {
    aggregatedServiceData(state, e) {
      state.nodes = e.nodes
      let connectedRealDevices = 0
      let connectedWebClients = 0
      let connectedClients = 0
      state.nodes.forEach(node => {
        node.Services.forEach(s => {
          if (s.Service === 'device-server' && s.metadata && s.metadata.optilink) {
            connectedRealDevices += s.metadata.optilink.connectedRealDevices
          }
          if (s.Service === 'web-portal-backend' && s.metadata && s.metadata.optilink) {
            connectedWebClients += s.metadata.optilink.activeUsers
          }
          if (s.Service === 'client-server' && s.metadata && s.metadata.optilink) {
            connectedClients += s.metadata.optilink.activeUsers
          }
        })
      })
      state.counters.connected.realDevices = connectedRealDevices
      state.counters.connected.clients = connectedClients
      state.counters.connected.webClients = connectedWebClients
    },
    updateCounters(state, e) {
      state.counters.registered.realDevices = e.availableRealDevices
      state.counters.registered.virtualDevices = e.availableVirtualDevices
      state.counters.registered.bussinessGroups = e.availableBusinessGroups
      state.counters.registered.clients = e.availableClients
      state.counters.registered.webClients = e.availableWebClients

      state.counters.connected.virtualDevices = e.availableVirtualDevices
      state.counters.connected.bussinessGroups = e.availableBusinessGroups
    },
    logout(state) {
      state.counters.registered.realDevices = 0
      state.counters.registered.virtualDevices = 0
      state.counters.registered.bussinessGroups = 0
      state.counters.registered.clients = 0
      state.counters.registered.webClients = 0

      state.counters.connected.realDevices = 0
      state.counters.connected.virtualDevices = 0
      state.counters.connected.bussinessGroups = 0
      state.counters.connected.clients = 0
      state.counters.connected.webClients = 0
      state.nodes = []
    }
  }
}
