import api from '@/plugins/axios.js'

export function subscribeToDashboardUpdateEvents(session) {
  return api.get('/dashboard/subscribe', {
    params: {
      session: session
    }
  })
}
export function getAdditionalMetadata(target) {
  return api.get('/dashboard/service/metadata', {
    params: {
      target: target
    }
  })
}
export function subscribeToDeviceCommandEvents(session, hexacode) {
  return api.get('/events/device-commands', {
    params: {
      session: session,
      hexacode: hexacode
    }
  })
}
export function subscribeToDeviceUpdateEvents(session, hexacode) {
  return api.get('/events/device-update-events', {
    params: {
      session: session,
      hexacode: hexacode
    }
  })
}
export function subscribeToDeviceStatusEvents(session, hexacode) {
  return api.get('/events/device-status', {
    params: {
      session: session,
      hexacode: hexacode
    }
  })
}
export function unsubscribe(subscriptionkey, session) {
  return api.get('/events/unsubscribe', {
    params: {
      session: session,
      subscriptionkey: subscriptionkey
    }
  })
}

export function subscribeToPushNotifications(vapidSubscription) {
  return api.post('/events/notification/subscribe', vapidSubscription)
}

export function unsubscribeToPushNotifications(n) {
  return api.get('/events/notification/unsubscribe')
}

export function getVapidKey() {
  return api.get('/events/notification/key')
}
