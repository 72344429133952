import api from '@/plugins/axios.js'

export function findAllDomains() {
  return api.get('/domains/get')
}

export function deleteDomain(id) {
  return api.delete('/domains/delete', { params: { id } })
}

export function saveDomain(domain) {
  return api.post('/domains/save', domain)
}
export function updateDomain(domain) {
  return api.put('/domains/update', domain)
}
export function validateDomainName(id, name) {
  return api.get('/domains/validate/name', {
    params: {
      id: id,
      name: name
    }
  })
}
