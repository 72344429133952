import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueAxios from 'vue-axios'
import api from '@/plugins/axios'
import VueSweetalert2 from 'vue-sweetalert2'
import VueMask from 'v-mask'
import './registerServiceWorker'
import './vee-validate'
import './validation-rules/buffer-export-template-rules.js'
import './validation-rules/alarm-export-template-rules.js'
import './validation-rules/optimabox-v1-export-template-rules.js'
import './validation-rules/optimabox-v2-export-template-rules.js'

import WebSocket from '@/plugins/websocket.js'
import Notifications from '@/plugins/notifications.js'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import '@/mixins.js'
import '@/assets/scss/scroll.scss'
import '@/assets/scss/utils.scss'
import '@/assets/scss/theme.scss'
import 'sweetalert2/dist/sweetalert2.min.css'
import { Settings } from 'luxon'

import { hasAccess } from './directives/hasAccess'

Settings.defaultZoneName = 'utc'
Vue.config.productionTip = false
// load data from local storage first
Vue.use(VueMask, {
  placeholders: {
    H: /[A-Fa-f]|\d/
  }
})
Vue.use(VueSweetalert2, {
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33'
})
Vue.use(VueAxios, api)
Vue.use(WebSocket, {
  pingTimeout: 1000 * 30,
  pongTimeout: 1000 * 30,
  reconnectTimeout: 1000 * 15,
  pingMessage: 'heartbeat',
  store: store
})
Vue.use(Notifications, {
  store: store
})
Vue.use(hasAccess)
// load localstore before the app is created
store.commit('loadLocalStorage')
store.dispatch('loadTheme', vuetify)
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
