import { GRANTS, GRANT_CONFIG_TYPE } from '@/store/security/grants.js'
const routes = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    meta: {
      authenticationRequired: true
    },
    children: [
      {
        name: 'Dashboard',
        path: '/dashboard',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.SYSTEM.READ]
          }
        },
        component: () => import('@/components/dashboard/Index.vue')
      },
      {
        name: 'Devices',
        path: '/devices',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.DEVICES.READ]
          }
        },
        component: () => import('@/components/devices/Index.vue')
      },
      {
        name: 'SoftwareVersions',
        path: '/software-versions',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.DEVICE_UPDATES.READ]
          }
        },
        component: () => import('@/components/software-versions/Index.vue')
      },
      {
        name: 'DealersAndSubDealers',
        path: '/dealers-and-subdealers',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ANY,
            value: [GRANTS.DEALERS.READ, GRANTS.SUBDEALERS.READ]
          }
        },
        component: () => import('@/components/dealers-and-subdealers/Index.vue')
      },
      {
        name: 'Accounts',
        path: '/accounts',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.ACCOUNTS.READ]
          }
        },
        component: () => import('@/components/accounts/Index.vue')
      },
      {
        name: 'AccessGroups',
        path: '/access-groups',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.ACCESS_GROUPS.READ]
          }
        },
        component: () => import('@/components/access-groups/Index.vue')
      },
      {
        name: 'SubscriptionPlans',
        path: '/subscription-plans',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.SUBSCRIPTION_PLANS.READ]
          }
        },
        component: () => import('@/components/subscription-plans/Index.vue')
      },
      {
        name: 'Domains',
        path: '/domains',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.DOMAINS.READ]
          }
        },
        component: () => import('@/components/domains/Index.vue')
      },
      {
        name: 'Buffers',
        path: '/buffers',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.BUFFERS.READ]
          }
        },
        component: () => import('@/components/buffers/Index.vue')
      },
      {
        name: 'ExportTemplates',
        path: '/export-templates',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.EXPORT_TEMPLATES.READ]
          }
        },
        component: () => import('@/components/export-templates/Index.vue')
      },
      {
        name: 'AdfManagement',
        path: '/adf-management',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.ADF_FILES.READ]
          }
        },
        component: () => import('@/components/adf-management/Index.vue')
      },
      {
        name: 'ExportMonitor',
        path: '/export-monitor',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.EXPORT_MONITOR.READ]
          }
        },
        component: () => import('@/components/export-monitor/Index.vue')
      },
      {
        name: 'KeyPairManagement',
        path: '/key-pair-management',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.SYSTEM.READ]
          }
        },
        component: () => import('@/components/key-pair-management/Index.vue')
      },
      {
        name: 'VirtualDevicesManagement',
        path: '/virtual-devices-management',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.SYSTEM.READ]
          }
        },
        component: () => import('@/components/virtual-devices/Index.vue')
      },
      {
        name: 'Storyblok',
        path: '/storyblok',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.STORYBLOK.READ]
          }
        },
        component: () => import('@/components/storyblok/Index.vue')
      },
      {
        name: 'BugReports',
        path: '/support/bug-reports',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.SUPPORT.READ]
          }
        },
        component: () => import('@/components/support/bug-reports/BugReports.vue')
      },
      {
        name: 'FeatureRequests',
        path: '/support/feature-requests',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.SUPPORT.READ]
          }
        },
        component: () => import('@/components/support/feature-requests/FeatureRequests.vue')
      },
      {
        name: 'Roles',
        path: '/security/roles',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.SECURITY.READ]
          }
        },
        component: () => import('@/components/security/Roles.vue')
      },
      {
        name: 'SecurityPolicies',
        path: '/security/policies',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.SECURITY.READ]
          }
        },
        component: () => import('@/components/security/SecurityPolicies.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/DeviceControlPanelLayout.vue'),
    props: route => (route.query),
    children: [
      {
        name: 'DeviceControlPanel',
        path: '/device-control-panel',
        meta: {
          grants: {
            type: GRANT_CONFIG_TYPE.ALL,
            value: [GRANTS.DEVICE_CONTROL_PANEL.READ]
          }
        },
        props: route => (route.query),
        component: () => import('@/components/devices/control-panel/Index.vue')
      }
    ]
  },
  {
    name: 'ExportTemplatesHelp',
    path: '/export-templates-help',
    meta: {
      grants: {
        type: GRANT_CONFIG_TYPE.ALL,
        value: [GRANTS.EXPORT_TEMPLATES.READ]
      }
    },
    component: () => import('@/components/export-templates//help/ExportTemplatesHelp.vue')
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/pages/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/pages/ForgotPassword.vue')
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: () => import('@/pages/RecoverPassword.vue')
  },
  {
    path: '/setup-password',
    name: 'setup-password',
    component: () => import('@/pages/SetupPassword.vue')
  },
  {
    name: 'Unauthorized',
    path: '/unauthorized',
    meta: {},
    component: () => import('@/pages/Error403.vue')
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/Error404.vue')
  }
]
if (process.env.VUE_APP_PROFILE !== 'acceptance') {
  routes[0].children.push({
    name: 'FtpAccounts',
    path: '/ftp-accounts',
    meta: {
      grants: {
        type: GRANT_CONFIG_TYPE.ALL,
        value: [GRANTS.FTP_ACCOUNTS.READ]
      }
    },
    component: () => import('@/components/ftp-accounts/Index.vue')
  })
  routes[0].children.push({
    name: 'MailUsers',
    path: '/mail-users',
    meta: {
      grants: {
        type: GRANT_CONFIG_TYPE.ALL,
        value: [GRANTS.MAIL_ACCOUNTS.READ]
      }
    },
    component: () => import('@/components/mail-users/Index.vue')
  })
}
export default routes
